import React, { useState, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { VerticalCardInfo } from "@molecules/VerticalCardInfo";
import { HoverInfoCard } from "@molecules/HoverInfoCard";
import { FinancialAccessForm } from "@molecules/FinancialAccessForm";
import Footer from "@organism/Footer/Footer";
import { isBrowser } from "@utils/isBrowser";
import card1 from "@assets/image/financial_access1.png";
import card2 from "@assets/image/financial_access2.png";
import card3 from "@assets/image/financial_access3.png";
import info1 from "@assets/image/financial_access4.png";
import info2 from "@assets/image/financial_access5.png";
import info3 from "@assets/image/financial_access6.png";
import background1 from "@assets/image/financial_access8.png";
import background2 from "@assets/image/financial_access9.png";
import background3 from "@assets/image/financial_access10.png";
import "./styles.scss";
import PipeDriveChat from "@utils/pipedriveChat";

export const FinancialAccessContent = () => {

  const { t } = useTranslation();
  const [showForms, setShowForm] = useState({
    information: false,
    ong: false,
    book: false,
  });

  const handleOngChange = () =>
    setShowForm((prev) => ({
      ...prev,
      ong: !prev.ong,
    }));

  const handleInformationChange = () =>
    setShowForm((prev) => ({
      ...prev,
      information: !prev.information,
    }));
  const handleBookChange = () =>
    setShowForm((prev) => ({
      ...prev,
      book: !prev.book,
    }));

  const [chatStyle, setChatStyle] = useState(`textarea[class^="inputField_"]
    {
      color: #FFFFFF !important; 
       -webkit-text-fill-color: #FFFFFF !important;
    }`);
  const [clientChat, setClientChat] = useState(false);
  const [initChat, setinitChat] = useState(false);

  const handleOnLoad = () => {
    if (isBrowser) {
      if (window.screen.width > 800) {
        setTimeout(() => {
          setChatStyle(`textarea[class^="inputField_"]
            {
              color: #FFFFFF !important; 
               -webkit-text-fill-color: #FFFFFF !important;
            } `);
        }, 2500);
      }
    }
  };
  const openChat = () => {
    setinitChat(true);

    setClientChat(t("JIVOCHAT"));
    handleOnLoad();
  };

  useEffect(() => {
    if (initChat) {
      if (isBrowser && clientChat !== false && clientChat !== t("JIVOCHAT")) {
        window.location.reload();
      }
    }
  });
  return (
    <>
      <main className="main_container">
        <article className="head_content">
          <h1 className="title">{t("FINANCIAL_ACCESS.MAIN_CONTENT.TITLE")}</h1>
          <p className="content">
            {t("FINANCIAL_ACCESS.MAIN_CONTENT.CONTENT")}
          </p>
        </article>

        <article className="cards_details">
          <VerticalCardInfo
            cardImage={card1}
            id="n5_solutions"
            lineHeight={230}
          >
            {showForms.information ? (
              <FinancialAccessForm type="ong" hidden={!showForms.information} />
            ) : (
              <>
                <h2 className="title">
                  {t("FINANCIAL_ACCESS.N5_SOLUTIONS.TITLE")}
                </h2>
                <p className="description">
                  {t("FINANCIAL_ACCESS.N5_SOLUTIONS.DESCRIPTION")}
                </p>
                <p className="description">
                  {t("FINANCIAL_ACCESS.N5_SOLUTIONS.SECONDARY_DESCRIPTION")}
                </p>
                <button onClick={handleInformationChange}>
                  {t("FINANCIAL_ACCESS.N5_SOLUTIONS.BUTTON")}
                </button>
              </>
            )}
          </VerticalCardInfo>
          <VerticalCardInfo
            reverse
            cardImage={card2}
            id="n5_friends"
            lineHeight={120}
          >
            {showForms.ong ? (
              <FinancialAccessForm hidden={!showForms.ong} type="information" />
            ) : (
              <>
                <h2 className="title">
                  {t("FINANCIAL_ACCESS.N5_FRIENDS.TITLE")}
                </h2>
                <p className="description">
                  {t("FINANCIAL_ACCESS.N5_FRIENDS.DESCRIPTION")}
                </p>
                <button onClick={handleOngChange}>
                  {t("FINANCIAL_ACCESS.N5_FRIENDS.BUTTON")}
                </button>
              </>
            )}
          </VerticalCardInfo>
        </article>
        <hr className="divider" />

        <article className="about_organizations">
          <h1 className="title">{t("FINANCIAL_ACCESS.FUNDATIONS.TITLE")}</h1>

          <div className="card_list">
            <a href="https://salvavidasvzla.org/" rel="noreferrer" target="_blank">
              <HoverInfoCard
                image={info1}
                overlay={background1}
                description={t("FINANCIAL_ACCESS.FUNDATIONS.VENEZUELA")}
              />
            </a>
            <a
              href="https://www.instagram.com/autismonapontadope/?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              <HoverInfoCard
                image={info2}
                overlay={background2}
                description={t("FINANCIAL_ACCESS.FUNDATIONS.BRASIL")}
              />
            </a>
            <a href="http://www.sagrado.edu.ar/" target="_blank" rel="noreferrer">
              <HoverInfoCard
                image={info3}
                overlay={background3}
                description={t("FINANCIAL_ACCESS.FUNDATIONS.ARGENTINA")}
              />
            </a>
          </div>
        </article>

        <hr className="divider" />

        <article className="cards_details">
          <VerticalCardInfo
            reverse
            cardImage={card3}
            id="books_donations"
            lineHeight={140}
          >
            {showForms.book ? (
              <FinancialAccessForm
                hidden={!showForms.book}
                type="information"
              />
            ) : (
              <>
                {t("FINANCIAL_ACCESS.BOOKS_DONATIONS.TITLE")
                  .split(",")
                  .map((text, key) => (
                    <h2 className="title" key={key}>
                      {key === 0 ? `${text},` : text}
                    </h2>
                  ))}
                <p className="description">
                  {t("FINANCIAL_ACCESS.BOOKS_DONATIONS.DESCRIPTION")}
                </p>
                <button onClick={handleBookChange}>
                  {t("FINANCIAL_ACCESS.BOOKS_DONATIONS.BUTTON")}
                </button>
              </>
            )}
          </VerticalCardInfo>
        </article>

        <article className="head_content">
          <h2 className="title">
            {t("FINANCIAL_ACCESS.HUMANING_DIGITAL.TITLE")}
          </h2>
        </article>
      </main>
      {/* <Chat
        chatStyle={chatStyle}
        clientChat={clientChat}
        initChat={initChat}
        openChat={openChat}
      /> */}
      <PipeDriveChat />
      <Footer openChat={openChat} />
    </>
  );
};
