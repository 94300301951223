import React from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Navbar from "@organism/Navbar/Navbar";
import SEO from "../components/Seo";
import { FinancialAccessContent } from "@organism/FinancialAccessContent";

const FinancialAccess = ({ location }) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  
  return (
    <>
      <SEO
        title={t("SEO.FINANCIAL_ACCESS.TITLE")}
        description={t("SEO.DIGITAL_AMERICA.DESCRIPTION")}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      <Navbar platform location={location} />
      <FinancialAccessContent />
    </>
  );
};

export default FinancialAccess;
