import React from "react";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { financialAccessSchema } from "./validations";
import { financialAccess } from "../../../services/sendEmail";
import { FieldInput } from "@atoms/FieldInput";
import "./styles.scss";
import { Loading } from "@atoms/Loading";

export const FinancialAccessForm = ({ hidden = false, type }) => {
  const { t } = useTranslation();

  const listFields = [
    {
      type: "email",
      label: t("FINANCIAL_ACCESS.FORM.EMAIL"),
      name: "email",
      placeholder: t("FINANCIAL_ACCESS.FORM.EMAIL_PLACEHOLDER"),
    },
    {
      type: "text",
      label: t("FINANCIAL_ACCESS.FORM.SUBJECT"),
      name: "subject",
      disabled: true,
    },
    {
      as: "textarea",
      label: t("FINANCIAL_ACCESS.FORM.MESSAGE"),
      name: "message",
    },
  ];

  const initialVal = {
    email: "",
    subject:
      type === "ong"
        ? t("FINANCIAL_ACCESS.FORM.CONTACT_US_SUBJECT")
        : t("FINANCIAL_ACCESS.FORM.DONATE_SUBJECT"),
    message: t("FINANCIAL_ACCESS.FORM.MESSAGE_CONTENT"),
  };

  const handleSubmit = async ({ email, subject, message }, actions) => {
    try {
      const { status } = await financialAccess(email, subject, message);
      if (status === 200) {
        actions.setStatus({ message: "Email enviado", error: false });
      } else {
        actions.setStatus({ message: "Error al enviar email" });
      }
    } catch (error) {
      actions.setStatus({ message: "Error al enviar email" });
    }
    await new Promise((resolve) => setTimeout(resolve, 2000));
    actions.resetForm();
  };

  return (
    <>
      <Formik
        initialValues={initialVal}
        onSubmit={handleSubmit}
        validationSchema={financialAccessSchema(t)}
      >
        {({ isSubmitting, status }) => (
          <>
            <Form className={`fa_form ${hidden && "hidden"}`}>
              {isSubmitting && (
                <>
                  {status ? (
                    <div>
                      <p className="status_message">{status.message}</p>
                    </div>
                  ) : (
                    <Loading />
                  )}
                </>
              )}

              {!isSubmitting && (
                <>
                  {listFields.map((field, index) => (
                    <div key={index} className="field_container">
                      <FieldInput {...field} />
                    </div>
                  ))}
                  <button type="submit" className="fa_submit_button">
                    {t("FINANCIAL_ACCESS.FORM.SEND")}
                  </button>
                </>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

FinancialAccessForm.propTypes = {
  hidden: PropTypes.bool,
  type: PropTypes.oneOf(["ong", "information"]).isRequired,
};
