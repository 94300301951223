import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

export const ScrollLine = ({ height }) => {
  return (
    <span className="scroll_Line_container">
      <div className="line" style={{ height: `${height}px` }}></div>
    </span>
  );
};

ScrollLine.propTypes = {
  height: PropTypes.number.isRequired,
};
