import * as yup from "yup";


export const financialAccessSchema = (t) => yup.object().shape({
  email: yup
    .string()
    .email(t("FINANCIAL_ACCESS.FORM.VALIDATION_EMAIL"))
    .required(t("FINANCIAL_ACCESS.FORM.REQUIRED_FIELD")),
    subject: yup.string().required(t("FINANCIAL_ACCESS.FORM.REQUIRED_FIELD")),
    message: yup.string().required(t("FINANCIAL_ACCESS.FORM.REQUIRED_FIELD"))
});
