import React from "react";
import PropTypes from "prop-types";
import { ScrollingGuide } from "@molecules/ScrollingGuide";
import "./styles.scss";

export const VerticalCardInfo = ({
  reverse = false,
  cardImage,
  children,
  id,
  lineHeight,
}) => {
  return (
    <div className="vertical_card_container" id={id}>
      <div className={`card ${reverse ? "reverse" : ""}`}>
        <img src={cardImage} alt="card" loading="lazy" />
        <ScrollingGuide
          id={id}
          lineHeight={lineHeight}
          style={{ top: `-${lineHeight - 15}px` }}
        />
      </div>
      <div className={`card_text_info ${reverse ? "reverse" : ""}`}>
        {children}
      </div>
    </div>
  );
};

VerticalCardInfo.propTypes = {
  reverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
  cardImage: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  lineHeight: PropTypes.number.isRequired,
};
