import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

export const HoverInfoCard = ({ image, overlay, description }) => {
  return (
    <div className="hover_info_container">
      <div className="image_container">
        <img src={image} alt="info hover card" className="main_image" />

        <div className="overlay">
          <img className="image" alt="info overlay card" src={overlay} />
        </div>
      </div>

      <p className="card_info">{description}</p>
    </div>
  );
};

HoverInfoCard.propTypes = {
  image: PropTypes.any.isRequired,
  overlay: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
};
