import React from "react";
import "./styles.scss";

export const ScrollCircle = () => {
  return (
      <div className="scroll_circle_container">
        <div className="outer_circle">
          <div className="inner_circle"></div>
        </div>
      </div>
  );
};
