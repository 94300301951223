import React from "react";
import PropTypes from "prop-types";
import { ScrollCircle } from "@atoms/ScrollCircle";
import { ScrollLine } from "@atoms/scrollLine";
import "./styles.scss";

export const ScrollingGuide = ({ id, lineHeight, ...props }) => {
  return (
    <div className="scrolling_guide_container" {...props}>
      <div className="scrolling_guide_content">
        <ScrollLine height={lineHeight} />
        <a href={`#${id}`}>
          <ScrollCircle />
        </a>
      </div>
    </div>
  );
};

ScrollingGuide.propTypes = {
  id: PropTypes.string.isRequired,
  lineHeight: PropTypes.number.isRequired,
  style: PropTypes.object,
};
